export const QUVMQ1 = [
  {
    subCap: "tugo.mq.q1",
    code: "QT-VIS-1",
    content: [
      {
        q: "tugo.mq.q1.a1",
        code: "q1a1",
      },
      {
        q: "tugo.mq.q1.a2",
        code: "q1a2",
      },
      {
        q: "tugo.mq.q1.a3",
        code: "q1a3",
      },
      { q: "tugo.mq.q1.a4", code: "q1a4", exclusive: "yes" },
    ],
  },
  {
    subCap: "tugo.mq.q2",
    code: "QT-VIS-2",
    extraLine: "tugo.mq.q2.description",
    content: [
      {
        q: "tugo.mq.q2.a1",
        code: "q2a1",
      },
      {
        q: "tugo.mq.q2.a2",
        code: "q2a2",
      },
      {
        q: "tugo.mq.q2.a3",
        code: "q2a3",
      },
      {
        q: "tugo.mq.q2.a4",
        code: "q2a4",
      },
      { q: "tugo.mq.q2.a5", code: "q2a5" },
      {
        q: "tugo.mq.q2.a6",
        code: "q2a6",
      },
      {
        q: "tugo.mq.q2.a7",
        code: "q2a7",
      },
      { q: "tugo.mq.q2.a8", code: "q2a8" },
      { q: "tugo.mq.q2.a9", code: "q2a9", exclusive: "yes" },
    ],
  },
  {
    subCap: "tugo.mq.q3",
    code: "QT-VIS-3",
    content: [
      {
        q: "tugo.mq.q3.a1",
        code: "q3a1",
        exclusive: "yes",
      },
      {
        q: "tugo.mq.q3.a2",
        code: "q3a2",
        exclusive: "yes",
      },
    ],
  },
]

const ex = {
  mq: {
    code: "QU-VMQ-1",
    questions: [
      {
        code: "QT-VIS-1",
        answers: [
          {
            code: "q1a4",
            value: -1,
          },
        ],
      },
      {
        code: "QT-VIS-2",
        answers: [
          {
            code: "q2a9",
            value: -1,
          },
        ],
      },
      {
        code: "QT-VIS-3",
        answers: [
          {
            code: "q3a2",
            value: -1,
          },
        ],
      },
    ],
  },
}
