import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  PrimaryButton,
  InfoCardCheckbox,
  GoBack,
} from "../components"
import { QUVMQ1 } from "../utils/tugo-mq"
import { getCurrentPath } from "../redux/actions"
import { mq } from "../utils/media-query"
import { validTravellers } from "../utils/helpers"

const validMQ = traveller => {
  let valid = true
  const mq = traveller.mq
  for (const question of mq.questions) {
    valid = false
    const answers = question.answers
    for (const answer of answers) {
      if (answer.value === 1) {
        valid = true
      }
    }
  }
  return valid
}

export const MedicalQuestionnaire = ({ location }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const travellers = useSelector(state => state.travellers)
  const travellersNeedMQ = travellers.filter(t => t.is60yrsOrOlder === "y")
  const [isValidTravellers, setIsValidTravellers] = useState(true)
  const [continueOK, setContinueOK] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    dispatch(getCurrentPath(location.pathname))
    setIsValidTravellers(validTravellers(travellers))
    if (travellersNeedMQ.length <= 0) navigate("/get-quote-policies")
  }, [getCurrentPath, location.pathname, dispatch, travellersNeedMQ])

  useEffect(() => {
    if (travellersNeedMQ.length > 0)
      setContinueOK(validMQ(travellersNeedMQ[index]))
  }, [travellersNeedMQ, index])

  const onProceed = () => {
    if (index + 1 > travellersNeedMQ.length - 1) {
      navigate("/get-quote-policies")
      return
    }
    setIndex(index + 1)
  }

  const onGoBackToPrevPage = () => {
    if (index - 1 < 0) {
      navigate("/get-quote-travellers")
      return
    }
    setIndex(index - 1)
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - medical questionnaire" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidTravellers ? (
          <SectionCenter>
            <IndividualMQ traveller={travellersNeedMQ[index]} />
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <PrimaryButton
                textSize="0.875rem"
                textTransform="capitalize"
                maxWidth="none"
                margin="0 4rem 0 0"
                onClick={onProceed}
                disabled={!continueOK}
              >
                {intl.formatMessage({ id: "btn.continue" })}
              </PrimaryButton>
              <SecondaryButton
                buttonColor="var(--clr-neutral-400)"
                textSize="0.875rem"
                margin="none"
                onClick={onGoBackToPrevPage}
              >
                {intl.formatMessage({ id: "btn.goback" })}
              </SecondaryButton>
            </div>
          </SectionCenter>
        ) : (
          <GoBack
            backToLink="/get-quote-travellers"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem2" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const IndividualMQ = ({
  traveller = {
    first_name: "john",
    last_name: "doe",
    birth_date: "",
    relationship_status: "policyholder",
    gender: "",
    is60yrsOrOlder: "",
    id: "policyholder",
    mq: {
      code: "QU-VMQ-1",
      questions: [
        {
          code: "QT-VIS-1",
          answers: [
            {
              code: "q1a4",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-2",
          answers: [
            {
              code: "q2a9",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-3",
          answers: [
            {
              code: "q3a2",
              value: -1,
            },
          ],
        },
      ],
    },
  },
}) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <Title
        title={`${intl.formatMessage({
          id: "tugo.mq.heading",
        })} - ${traveller.first_name.replace(/\b(\w)/, l =>
          l.toUpperCase()
        )} ${traveller.last_name.replace(/\b(\w)/, l => l.toUpperCase())}`}
        fontColor="var(--clr-primary-300)"
        subcap={intl.formatMessage({ id: "tugo.mq.sub" })}
      />
      <div
        css={css`
          width: 80%;
          display: grid;
          row-gap: 2rem;
          margin: 0 auto 4rem auto;

          /* ${mq[0]} {
            width: 60%;
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            column-gap: 3rem;
            justify-content: center;
          } */
        `}
      >
        <InfoCardCheckbox data={QUVMQ1} traveller={traveller} />
      </div>
    </React.Fragment>
  )
}

export default MedicalQuestionnaire
